.MuiButton-containedSecondary {
  background-color: #5fa30f !important;
  font-size: 22px;
  padding: 5px 25px;
  height: 45px;
}

.MuiButton-containedSecondary:hover {
  background-color: #5fa30f !important;
  opacity: 0.8; 
}

.payment-modal-contentimg2 {
  vertical-align: bottom;
}

.payment-modal-content2 {
  display: inline-block;
  contain: content;
}

.payment-popup-content2 {
  padding: 50px;
}

.ant-spin-lg .ant-spin-dot {
  font-size: 32px;
  /* margin-bottom: 60px; */
}

.display-payment-content{
  display: flex;
}

.icon-loader{
  margin-top: 20px;
}