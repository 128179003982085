.ant-modal-confirm-body .ant-modal-confirm-title {
text-align: center;
    color: #5FA30F;
    font-size: 25px;
}

/* .confirm-popup-title{
    vertical-align: baseline;
    margin-left: 20px;
    width: 50px;
    height:50px;
    max-width: none;
} */

.confirm-popup-img{
    width: 100px;
    height:100px;
    vertical-align: bottom;
}

.confirm-popup-content{
    display: inline-grid;
}

p{
    margin:0px
}

.confirm-popup-text{
    font-size: 20px;
    margin-left: 30px;
}

.ant-modal-confirm-body .ant-modal-confirm-content {
    margin-left: 0px !important; 
    margin-top: 30px;
    padding: 0px;
}

/* .ant-modal-confirm-body{
    display: inline-flex;
    font-size: 20px;
} */

/* .confirm-popup-title{
    color: #5FA30F;
} */