.left-side-title {
    margin-left: 20px;
    margin-bottom: 20px;
}

.field2 {
    display: flex;
}

.left-side-form {
    margin-top: 20px;
}

.flex-view-cvv-payment {
    max-width: 400px;
    width: 400px;
}

.submit-button {
    width: -webkit-fill-available;
}

.ant-form-explain2 .ant-form-explain {
    margin-bottom: 5px;
}

.card-elements {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: "tnum", "tnum";
    position: relative;
    display: inline-block;
    width: 100%;
    height: 36px;
    padding: 6px 11px;
    color: #545454;
    font-size: 14px;
    line-height: 1.3;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    transition: all 0.3s;
}

.card-elements2 {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: "tnum", "tnum";
    position: relative;
    display: inline-block;
    width: 50%;
    height: 36px;
    padding: 6px 11px;
    color: #545454;
    font-size: 14px;
    line-height: 1.3;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    transition: all 0.3s;
}

.reduce-margin {
    margin-bottom: 0px;
}

.error-display {
    color: red;
    margin-bottom: 10px;
}

.card-number-popup-text {
    width: 206%;
}

.card-number-popup-content2 {
    text-align: center;
}

@media only screen and (max-width: 1200px) {
    .confirm-popup-text {
        width: 136%;
    }
}

@media only screen and (max-width: 600px) {
    .confirm-popup-text {
        width: 88%;
    }
}

@media only screen and (max-width: 500px) {
    .confirm-popup-text {
        width: 100%;
        margin: 0px !important;
    }
}

@media only screen and (max-width: 576px) {
    .card-popup{
        text-align: center !important;
        width: 96% !important;
    }
}

@media only screen and (max-width: 502px) {
    .card-popup{
        text-align: center !important;
        width: 100% !important;
    }
}


.card-popup{
    text-align: center;
    width: 67% ;
}

.discountModal{
    width: 67%;
    padding: 10px 0px;
    font-size: 20px;
    border: 1px solid black;
    border-style: dashed;
    background-color: lightgray;
    margin-bottom: 10px;
    border-radius: 4px
}

.discountModalSubmitButton{
    width: 67%
}

.submitButton{
    height: 46px;
    font-size: 20px;
    width: 100%
}

@media only screen and (max-width: 576px) {
    .discountModal{
        width: 96%
    }
    .discountModalSubmitButton{
        width: 96%
    }
}

.confirm-popup-img{
    width: 40% !important;
    margin-left: 30% !important;
    margin-bottom: 60px !important;
}

.closeButonWrapper{
    text-align: end;
    margin-bottom: -46px;
}

.closeButton{
    font-size: 30px;
    border: 0px;
}

.card-popup-title{
    text-align: center;
    width: 67% ;
    font-size: xx-large;
    font-weight: bold

}

.card-popup-message{
    text-align: center;
    width: 67% ;
    font-size: large;
}

.cardDiscountMessage{
    text-align: center;
    width: 67% ;
    font-size: 20px
}