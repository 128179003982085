.ant-spin-spinning {
    position: static;
    display: flex;
    opacity: 1;
}

.ant-spin.ant-spin-show-text .ant-spin-text {
    display: block;
    margin-left: 10px;
}

.paybutton-loader{
    margin-left: 20px;
    margin-top: 5px;
}