/* canoja verify --- DROPDOWN */
.cv-dropdown .ant-form-inline {
  display: flex;
}

.cv-dropdown .ant-form-inline > div:first-child {
  flex: 2;
  display: flex;
}

.cv-dropdown .ant-form-inline > div:last-child {
  flex: 2;
}
.cv-dropdown
  .ant-form-inline
  > div:first-child
  > .ant-form-item-control-wrapper {
  flex: 1;
}
/* canoja verify --- END */

.gx-main-content-wrapper {
  width: 94vw;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
}

.ant-menu-horizontal > .ant-menu-item > a {
  color: white;
}

.ant-menu-dark .ant-menu-item > a {
  color: white;
}

.ant-menu-horizontal > .ant-menu-item > a:hover,
.ant-menu-horizontal > .ant-menu-item > a:focus {
  color: #d4b162;
}

/* canoja verify --- turn drop down menu to black */
.gx-header-horizontal-nav
  .ant-menu-submenu-horizontal
  > .ant-menu-submenu-title {
  color: white;
}

.gx-container {
  width: 94vw;
}

.ant-btn.ant-btn-loading {
  position: fixed;
  top: calc(50% - 25px);
  left: calc(50% - 25px);
  z-index: 999999;
  width: 50px;
  height: 50px;
}

.ant-pagination {
  margin-top: 10px;
}

.ant-pagination > li {
  margin-bottom: 0;
}

.ant-carousel .slick-list .slick-slide.slick-active > div {
  height: 100%;
}

.ant-carousel .slick-list .slick-slide.slick-active > div > div {
  height: 100%;
}

.ant-carousel .slick-list .slick-slide.slick-active > div > div img {
  height: 100%;
  object-fit: fill;
}

.gx-header-search.right-logo {
  width: 120px;
  /* width: 300px; -- Used this with the 420 Happy Logo*/
  position: absolute;
  /* right: 20px; */
  right: 135px;
  height: 120px; /* canoja verify --- changed from 100% to 120px after moving tagline */
}

@media only screen and (max-width: 1760px) {
  .gx-header-search.right-logo {
    right: 129px;
  }
}
@media only screen and (max-width: 1690px) {
  .gx-header-search.right-logo {
    right: 126px;
  }
}

@media only screen and (max-width: 1420px) {
  .gx-header-search.right-logo {
    right: 117px;
  }
}

@media only screen and (max-width: 1120px) {
  .gx-header-search.right-logo {
    right: 107px;
  }
}

.age-container.gx-app-login-container {
  max-width: 450px;
}

.age-container .gx-app-login-main-content {
  flex-direction: column;
  padding: 30px;
  background: linear-gradient(
      rgba(255, 255, 255, 0.7),
      rgba(255, 255, 255, 0.7)
    ),
    url("../assets/images/canoja-leaf-gold.png");
  background-size: 100% 100%;
}

.age-container .gx-app-login-main-content h1 {
  font-size: 32px;
  margin-bottom: 30px;
}

.age-container .gx-app-login-main-content h2 {
  font-size: 24px;
  margin-bottom: 0;
}

.age-container .gx-app-login-main-content h3 {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 20px;
}

.age-container .gx-app-login-main-content button {
  color: #5fa333;
  background-color: #f6f6f6;
}

.age-container
  .ant-checkbox-wrapper.ant-checkbox-wrapper-checked:hover
  .ant-checkbox-checked::after {
  border: none;
}

.age-container .ant-checkbox-wrapper {
  display: flex;
}

.age-container .ant-form-explain {
  font-size: 20px;
  margin-top: 15px;
  color: #f5222d;
}

.gx-header-horizontal-main.ant-layout-header {
  padding-bottom: 5px;
}

.gx-header-horizontal-main.ant-layout-header h1 {
  margin-bottom: 0;
}

.ant-card.gx-card-widget:hover {
  /* box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.08); */
  box-shadow: 0 0 5px 5px rgba(95, 163, 15, 0.3);
}

@media screen and (max-width: 767px) {
  .ant-carousel .slick-list .slick-slide.slick-active > div > div img {
    object-fit: cover;
  }

  .cv-dropdown.license-table .ant-form-inline > div:first-child {
    flex: 1;
  }

  .cv-dropdown.license-table .ant-form-inline > div:last-child {
    flex: inherit;
    width: 100px;
  }
}

@media (max-width: 1199px) {
  .cv-dropdown.license-table .ant-form-inline > div:first-child {
    flex: 4 1;
  }
}

.gx-header-horizontal-dark .gx-linebar {
  color: rgb(95, 163, 15);
}

.gx-layout-sider-scrollbar > div:first-child {
  overflow: auto;
}

.gx-logo-image {
  width: 300px;
}

@media screen and (max-width: 576px) {
  .gx-logo-image {
    width: 250px;
  }
}

@media screen and (max-width: 350px) {
  .gx-logo-image {
    width: 220px;
  }
}

/* Sidebar */
.gx-layout-sider-header {
  box-shadow: none;
  background-color: transparent !important;
}

.gx-drawer-sidebar-dark .ant-drawer-content {
  background-color: white;
}

.gx-sidebar-content .ant-menu-item > a,
.gx-sidebar-content .ant-menu {
  color: #5fa30f;
}

.ant-carousel {
  display: block;
}

@media screen and (max-width: 768px) {
  .ant-carousel {
    display: none;
  }
}

#chartdiv {
  min-height: 550px;
  width: 100%;
  max-width: 1360px;
  margin: auto;
}

.gx-signup-form > div {
  margin-bottom: 10px;
}

.gx-signup-form #date-picker {
  margin-bottom: 0 !important;
}

.required label::before {
  color: #e32;
  content: " * ";
  display: inline;
}

.purchase-page .ant-pagination {
  text-align: right;
}

.purchase-page .ant-table-wrapper {
  min-height: 350px;
}

.purchase-page-form {
  max-width: 500px;
  display: flex;
  flex-direction: column;
  float: right;
}

.cost-breakdown-page-form {
  max-width: 800px;
  display: flex;
  flex-direction: column;
  float: left;
}

.purchase-page-form:after {
  clear: both;
}

.purchase-page-form-price h6,
.purchase-page-form-price p {
  font-size: 18px;
  margin: 0;
}

.purchase-page-form-price h6 {
  margin-bottom: 25px;
}

.purchase-page-form-price {
  margin-top: 20px;
  border-top: 2px solid;
  border-bottom: 2px solid;
  padding: 5px 0;
  margin-bottom: 20px;
}

.purchase-page-form label.ant-checkbox-wrapper {
  line-height: 1.5;
  display: flex;
  align-items: center;
}

.purchase-page-form .privay-policy .ant-form-item {
  justify-content: flex-end;
  margin-bottom: 10px;
}

.purchase-page-form
  .privay-policy
  .ant-form-item:last-child
  > .ant-form-item-control-wrapper {
  border-bottom: 2px solid;
  padding-bottom: 5px;
}

.purchase-button-group {
  text-align: right;
}

.purchase-button-group button.ant-btn {
  /* font-size: 22px;
  padding: 5px 25px;
  height: 45px; */
}

.purchase-button-group button.ant-btn:first-child {
  background-color: grey !important;
  border: none !important;
}

.purchase-button-group button.ant-btn:first-child:active {
  background-color: grey !important;
}

.purchase-page .has-error .ant-checkbox-inner {
  border-color: red;
}

.ant-modal-header,
.ant-modal-close {
  display: none;
}

.ant-modal-body {
  padding-top: 40px;
  padding-bottom: 40px;
}

span#birthdate {
  margin-bottom: 0 !important;
}

.gx-app-login-content .ant-form-item-control {
  line-height: 20px;
}

#passwordRequirements {
  display: none;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  white-space: nowrap !important;
}

.cv-dropdown.license-table > .ant-row > .ant-col {
  margin-bottom: 32px;
}

.cv-dropdown.license-table
  > .ant-row
  > .ant-col
  > div.gx-card.ant-card-bordered {
  margin-bottom: 0;
  height: 100%;
}

.set-red {
  color: red;
}

.set-green {
  color: #5fa30f;
}

.gx-app-logo-content-bg {
  background-color: black;
}

.gx-app-logo-content-bg::before {
  background-color: black;
}

.gx-app-logo-wid {
  margin-bottom: 0;
}

.purchase-table
  > div
  > div
  > div
  > div
  > div
  > table
  > tbody
  > tr
  > td:nth-child(8),
.purchase-table
  > div
  > div
  > div
  > div
  > div
  > table
  > tbody
  > tr
  > td:nth-child(3),
.purchase-table
  > div
  > div
  > div
  > div
  > div
  > table
  > tbody
  > tr
  > td:nth-child(4),
.purchase-table
  > div
  > div
  > div
  > div
  > div
  > table
  > tbody
  > tr
  > td:nth-child(5),
.purchase-table
  > div
  > div
  > div
  > div
  > div
  > table
  > tbody
  > tr
  > td:nth-child(6),
.purchase-table
  > div
  > div
  > div
  > div
  > div
  > table
  > tbody
  > tr
  > td:nth-child(7),
.purchase-table
  > div
  > div
  > div
  > div
  > div
  > table
  > tbody
  > tr
  > td:nth-child(13),
.purchase-table
  > div
  > div
  > div
  > div
  > div
  > table
  > tbody
  > tr
  > td:nth-child(11),
.purchase-table
  > div
  > div
  > div
  > div
  > div
  > table
  > tbody
  > tr
  > td:nth-child(12),
.purchase-table
  > div
  > div
  > div
  > div
  > div
  > table
  > tbody
  > tr
  > td:nth-child(21) {
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
  background-color: #ccc;
  user-select: none;
}

/* .purchase-table-enterprise-plan > div > div > div > div > div > table > tbody > tr > td:nth-child(8),
.purchase-table-enterprise-plan > div > div > div > div > div > table > tbody > tr > td:nth-child(3),
.purchase-table-enterprise-plan > div > div > div > div > div > table > tbody > tr > td:nth-child(4),
.purchase-table-enterprise-plan > div > div > div > div > div > table > tbody > tr > td:nth-child(5),
.purchase-table-enterprise-plan > div > div > div > div > div > table > tbody > tr > td:nth-child(6),
.purchase-table-enterprise-plan > div > div > div > div > div > table > tbody > tr > td:nth-child(7),
.purchase-table-enterprise-plan > div > div > div > div > div > table > tbody > tr > td:nth-child(13),
.purchase-table-enterprise-plan > div > div > div > div > div > table > tbody > tr > td:nth-child(11),
.purchase-table-enterprise-plan > div > div > div > div > div > table > tbody > tr > td:nth-child(12) {
	-webkit-filter: blur(5px);
	-moz-filter: blur(5px);
	-o-filter: blur(5px);
	-ms-filter: blur(5px);
	filter: blur(5px);
	background-color: #ccc;
	user-select: none;
} */

.ant-modal {
  width: 640px !important;
}

.ant-modal-body {
  padding: 0;
}

.ant-modal-body .gx-app-login-container {
  width: 100%;
}

.ant-modal-footer {
  display: none;
}

.ant-modal-content {
  border-radius: 12px !important;
}

.gx-app-login-wrap {
  padding: 0;
}
