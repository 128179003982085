.left-side-title {
  margin-left: 20px;
  margin-bottom: 20px;
}

.field2 {
  display: flex;
}

.left-side-form {
  margin-top: 20px;
}

.flex-view-cvv-payment {
  max-width: 400px;
  width: 400px;
}

.submit-button {
  width: fit-content;
}

.ant-form-explain2 .ant-form-explain {
  margin-bottom: 5px;
}

.card-elements {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  height: 36px;
  padding: 6px 11px;
  color: #545454;
  font-size: 14px;
  line-height: 1.3;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  transition: all 0.3s;
}

.card-elements2 {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  position: relative;
  display: inline-block;
  width: 50%;
  height: 36px;
  padding: 6px 11px;
  color: #545454;
  font-size: 14px;
  line-height: 1.3;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  transition: all 0.3s;
}

.reduce-margin {
  margin-bottom: 0px;
}

.error-display {
  color: red;
  margin-bottom: 10px;
}


.card-error-handle>.ant-form-item-control-wrapper>.ant-form-item-control>.ant-form-explain {
  display: none;
}


.card-image {
  width: 50%;
  margin-left: 10px;
}


.image-seal{
  text-align: center;
}
.checkout-next-step {
  font-size: '23px' !important;
  padding: '5px 25px' !important;
  height: '45px' !important
}

@media only screen and (max-width: 936px) {
  .checkout-next-step-button {
    padding-left: 50% !important;
  }
}

@media only screen and (max-width: 1700px) {
  .checkout-next-step-button {
    padding-left: 73% !important;
  }
}

@media only screen and (max-width: 1376px) {
  .checkout-next-step-button {
    padding-left: 68% !important;
  }
}

@media only screen and (max-width: 1200px) {
  .checkout-next-step-button {
    padding-left: 83% !important;
  }
}

@media only screen and (max-width: 1000px) {
  .checkout-next-step-button {
    padding-left: 78% !important;
  }
}

@media only screen and (max-width: 816px) {
  .checkout-next-step-button {
    padding-left: 70% !important;
  }
}

@media only screen and (max-width: 620px) {
  .checkout-next-step-button {
    padding-left: 57% !important;
  }
}

@media only screen and (max-width: 382px) {
  .checkout-next-step-button {
    padding-left: 45% !important;
  }
}

.checkout-next-step-button {
  padding-left: 81%;
}